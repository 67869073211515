import { ProductState } from '@/domain/product/model/product/enum';
import { ClientPlatform, CommonState } from '@frientrip/domain';
import { CollectionConditionExposureType } from './condition';
import { CollectionSlot } from './slot';

type Instant = number;

export interface Collection {
  id: string;
  title: string;
  displayProductSize: number | null;
  displayProductImage: boolean | null;
  displayMainImage: boolean | null;
  products: CollectionProductConnection;
  listType: CollectionListType | null;
  slot: CollectionSlot;
  seqNo: number;
  description: string;
  homeShowStartAt: Instant | null;
  homeShowEndAt: Instant | null;
  listShowStartAt: Instant | null;
  listShowEndAt: Instant | null;
  status: CommonState;
  isShownAtHome: boolean;
  isRandomOrder: boolean;
  content: { id: string };
  images: CollectionImage[];
}

export enum CollectionListType {
  GALLERY = 'GALLERY',
  HERO = 'HERO',
  TILE = 'TILE',
  POST = 'POST',
}

export interface CollectionConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: CollectionEdge[];
}

interface CollectionEdge {
  cursor: string;
  node: Collection;
}

interface CollectionProductConnection {
  totalCount: number;
  edges: CollectionProductEdge[];
  pageInfo: PageInfo;
}

interface PageInfo {
  hasNextPage: boolean;
  endCursor?: string;
}

export interface CollectionProductEdge {
  node: {
    id: string;
    status: ProductState;
    isBenepia: boolean;
    platformsOfCollecitonProduct: ClientPlatform[];
    collectionStatus?: CommonState;
  };
  cursor: string;
}

export interface CollectionImage {
  platform: ClientPlatform[];
  content: { id: string; uri: string };
  exposureType: CollectionConditionExposureType;
}

export interface CollectionConditionCreateParam {
  maxImageSize: number;
  imageWidth?: number;
  imageHeight?: number;
  platform: ClientPlatform[];
}

export interface CollectionVisibility {
  id: string;
  home: boolean;
  listing: boolean;
  randomOrder: boolean;
}

export interface CollectionSuccessStatus {
  success: boolean;
  message?: string;
  collections?: Collection[];
}

export interface CollectionProductParam {
  productId: string;
  displayable: boolean;
  isBenepia: boolean;
  platforms: ClientPlatform[];
}

export interface ProductCollectionUpdateParam {
  id: string;
  title: string;
  description: string;
  products: CollectionProductParam[];
}

export interface ProductCollectionCreateParam {
  title: string;
  description: string;
  products: CollectionProductParam[];
  slotId: string;
}

export interface CollectionImageContent {
  id: string;
  width: number;
  height: number;
  platform: ClientPlatform[];
  exposureType: CollectionConditionExposureType;
}

export interface ThemeCollectionUpdateParam {
  id: string;
  title: string;
  isRandomOrder: boolean;
  description: string;
  displayProductSize: number | null;
  displayProductImage: boolean | null;
  displayMainImage: boolean | null;
  listType: CollectionListType | null;
  imageContents: CollectionImageContent[];
  homeShowStartAt: Instant | null;
  homeShowEndAt: Instant | null;
  listShowStartAt: Instant | null;
  listShowEndAt: Instant | null;
  products: CollectionProductParam[];
  slotId: string;
}

export interface ThemeCollectionCreateParam {
  title: string;
  description: string;
  isRandomOrder: boolean;
  imageContents: CollectionImageContent[];
  homeShowStartAt: Instant | null;
  homeShowEndAt: Instant | null;
  listShowStartAt: Instant | null;
  listShowEndAt: Instant | null;
  displayProductSize: number | null;
  displayProductImage: boolean | null;
  displayMainImage: boolean | null;
  listType: CollectionListType | null;
  products: CollectionProductParam[];
  slotId: string;
}

export interface CollectionProductFilterInput {
  statusIn?: CommonState[];
  platformsIn?: ClientPlatform[];
}
