



































































































































































































































































































































































































































import Vue, { VueConstructor } from 'vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import LocalDateTimePicker from '@/components/Forms/LocalDateTimePicker.vue';
import { Option, Select, Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { apolloClient } from '@/apolloClient';
import draggable from 'vuedraggable';
import {
  COLLECTION_QUERY,
  LISTINGPRODUCTS_QUERY,
  SLOT_QUERY,
} from '../queries/query';
import { CollectionSlot } from '../model/slot';
import {
  Collection,
  CollectionImage,
  CollectionImageContent,
  CollectionListType,
  CollectionProductEdge,
  CollectionProductFilterInput,
  ThemeCollectionCreateParam,
  ThemeCollectionUpdateParam,
} from '../model/collection';
import {
  ListingProductsParam,
  ListingProductV4Connection,
  ListingProductV4Edge,
} from '../model/listingProduct';
import { CollectionService } from '../service/CollectionService';
import CollectionImageUpload from '../components/CollectionImageUpload.vue';
import {
  CollectionCondition,
  CollectionConditionExposureType,
} from '../model/condition';
import Description from '@/components/Labels/Description.vue';
import { ClientPlatform, CommonState } from '@frientrip/domain';
import { isDisplayable } from '../utils/convertProduct';
import OptionSelectList from '@/domain/product/components/Option/OptionSelectList.vue';
import { FripKind } from '@/domain/product/model/product/enum';

const collectionService = new CollectionService(apolloClient);

type TableListingProductEdge = ListingProductV4Edge & {
  node: { isChecked: boolean; platformsOfCollecitonProduct: ClientPlatform[] };
};

interface DraggableChangedEvent {
  added?: { element: TableListingProductEdge; newIndex: number };
  moved?: { element: unknown; newIndex: number; oldIndex: number };
}

export default // eslint-disable-next-line @typescript-eslint/no-explicit-any
(Vue as VueConstructor<Vue & { $refs: { [key: string]: any } }>).extend({
  name: 'CollectionThemeForm',
  components: {
    OptionSelectList,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
    draggable,
    ReservationTime: LocalDateTimePicker,
    CollectionImageUpload,
    Description,
  },
  data() {
    return {
      slot: {
        conditions: [] as CollectionCondition[],
      } as Omit<CollectionSlot, 'collections' | 'countOfVisibleCollections'>,
      isAnyChaning: true,
      isFirstView: true,
      slotId: this.$route.params.slotId,
      collectionId: this.$route.params.collectionId,
      isMeetup: false,
      form: {
        title: '',
        description: '',
        displayProductSize: 3,
        displayProductImage: true,
        displayMainImage: false,
        listType: CollectionListType.GALLERY,
        products: { edges: [] as CollectionProductEdge[] },
        images: [] as CollectionImage[],
        homeShowStartAt: null,
        homeShowEndAt: null,
        listShowStartAt: null,
        listShowEndAt: null,
      } as Collection,
      collectionClientQuery: {
        title: '',
        description: '',
        products: { edges: [] as CollectionProductEdge[] },
      } as Collection,
      collectionClientQueryWEB: {
        title: '',
        description: '',
        products: { edges: [] as CollectionProductEdge[] },
      } as Collection,
      collectionClientQueryMOBILE: {
        title: '',
        description: '',
        products: { edges: [] as CollectionProductEdge[] },
      } as Collection,
      collectionClientQueryAOS: {
        title: '',
        description: '',
        products: { edges: [] as CollectionProductEdge[] },
      } as Collection,
      collectionClientQueryiOS: {
        title: '',
        description: '',
        products: { edges: [] as CollectionProductEdge[] },
      } as Collection,
      products: [] as TableListingProductEdge[],
      productSearchType: 'name',
      productSearchOptions: [
        { value: 'name', text: '검색어' },
        { value: 'id', text: '상품ID' },
      ] as { value: string; text: string }[],
      searchParam: {
        page: 1,
        size: 30,
        filter: {},
      } as ListingProductsParam,
      searchText: '',
      loading: 0,
      productsLoading: 0,
      isHomeBooked: 'autoSetting',
      isListBooked: 'autoSetting',
      collectionStatusDone: false,
      collectionProductPlatformsDone: false,
      allClientPlatforms: [
        ClientPlatform.Web,
        ClientPlatform.Mobile,
        ClientPlatform.AndroidApp,
        ClientPlatform.iOSApp,
      ] as ClientPlatform[],
      collectionListTypeOptions: [
        {
          text: '갤러리',
          value: CollectionListType.GALLERY,
        },
        {
          text: '히어로',
          value: CollectionListType.HERO,
        },
        {
          text: '타일',
          value: CollectionListType.TILE,
        },
        {
          text: '포스트',
          value: CollectionListType.POST,
        },
      ],
      displayProductImageOptions: [
        {
          text: '상품 이미지',
          value: true,
        },
        {
          text: '기획전 이미지',
          value: false,
        },
      ],
      displayMainImageOptions: [
        {
          text: '노출',
          value: true,
        },
        {
          text: '미노출',
          value: false,
        },
      ],
      isRandomOrderOptions: [
        {
          text: '랜덤',
          value: true,
        },
        {
          text: '순서대로',
          value: false,
        },
      ],
    };
  },
  computed: {},
  watch: {
    // isMeetup 값이 변경될 때마다 자동으로 실행됩니다.
    isMeetup(newValue) {
      this.searchParam.filter.fripKindIn = newValue
        ? [FripKind.MEETUP]
        : [FripKind.COMMON];
    },
  },
  activated: async function () {
    await this.fetchCollections();
  },
  deactivated: function () {
    this.initData();
  },
  methods: {
    initData(): void {
      this.$refs.formValidator.reset();
      this.slot.conditions = [];
      this.form.products.edges = [];
      this.form.images = [];
      this.form.title = '';
      this.form.description = '';
      this.form.listShowStartAt = null;
      this.form.listShowEndAt = null;
      this.form.homeShowStartAt = null;
      this.form.homeShowEndAt = null;
      this.isHomeBooked = 'autoSetting';
      this.isListBooked = 'autoSetting';
      this.products = [];
      this.searchText = '';
      this.isFirstView = true;
      this.productSearchType = 'name';
      this.collectionClientQuery.products.edges = [];
      this.collectionClientQuery.id = '';
      this.collectionClientQueryWEB.products.edges = [];
      this.collectionClientQueryWEB.id = '';
      this.collectionClientQueryMOBILE.products.edges = [];
      this.collectionClientQueryMOBILE.id = '';
      this.collectionClientQueryAOS.products.edges = [];
      this.collectionClientQueryAOS.id = '';
      this.collectionClientQueryiOS.products.edges = [];
      this.collectionClientQueryiOS.id = '';
      this.collectionStatusDone = false;
      this.collectionProductPlatformsDone = false;
    },
    async fetchCollections(): Promise<void> {
      this.collectionStatusDone = false;
      this.collectionProductPlatformsDone = false;
      const promises = [];
      if (!this.isCreatePage()) {
        promises.push(this.$apollo.queries.form.refetch());
        promises.push(this.$apollo.queries.collectionClientQuery.refetch());
        promises.push(this.$apollo.queries.collectionClientQueryWEB.refetch());
        promises.push(
          this.$apollo.queries.collectionClientQueryMOBILE.refetch()
        );
        promises.push(this.$apollo.queries.collectionClientQueryiOS.refetch());
        promises.push(this.$apollo.queries.collectionClientQueryAOS.refetch());
      }
      promises.push(this.$apollo.queries.slot.refetch());
      await Promise.all(promises);
    },
    isActivateProduct(inputEdge: CollectionProductEdge): boolean {
      this.loadCollectionStatus();
      this.loadCollectionProductPlatforms();
      let result = false;
      if (inputEdge.node?.collectionStatus === CommonState.ACTIVE) {
        result = true;
      }
      return result;
    },
    loadCollectionStatus(): void {
      if (
        !this.loading &&
        !this.collectionStatusDone &&
        this.form.products.edges?.length &&
        this.collectionClientQuery?.id
      ) {
        let cursor = 0;
        for (let i = 0; i < this.form.products.edges.length; i++) {
          const targeId = this.form.products.edges[i].node.id;
          const activedId =
            this.collectionClientQuery.products.edges[cursor]?.node.id;
          if (activedId && targeId === activedId) {
            this.form.products.edges[i].node.collectionStatus =
              CommonState.ACTIVE;
            cursor += 1;
          }
          if (cursor === this.collectionClientQuery.products.edges.length) {
            break;
          }
        }
        this.collectionStatusDone = true;
      }
    },
    loadCollectionProductPlatforms(): void {
      if (
        !this.loading &&
        !this.collectionProductPlatformsDone &&
        this.form.products.edges?.length &&
        this.collectionClientQueryWEB?.id &&
        this.collectionClientQueryMOBILE?.id &&
        this.collectionClientQueryAOS?.id &&
        this.collectionClientQueryiOS?.id
      ) {
        const eachPlatformCursor: {
          [key in ClientPlatform]: {
            cursor: number;
            len: number;
            results: CollectionProductEdge[];
          };
        } = {
          [ClientPlatform.Web]: {
            cursor: 0,
            len: this.collectionClientQueryWEB.products.edges.length,
            results: this.collectionClientQueryWEB.products.edges,
          },
          [ClientPlatform.Mobile]: {
            cursor: 0,
            len: this.collectionClientQueryMOBILE.products.edges.length,
            results: this.collectionClientQueryMOBILE.products.edges,
          },
          [ClientPlatform.iOSApp]: {
            cursor: 0,
            len: this.collectionClientQueryiOS.products.edges.length,
            results: this.collectionClientQueryiOS.products.edges,
          },
          [ClientPlatform.AndroidApp]: {
            cursor: 0,
            len: this.collectionClientQueryAOS.products.edges.length,
            results: this.collectionClientQueryAOS.products.edges,
          },
        };

        for (let i = 0; i < this.form.products.edges.length; i++) {
          const nowProduct = this.form.products.edges[i];
          if (!nowProduct.node.platformsOfCollecitonProduct) {
            nowProduct.node.platformsOfCollecitonProduct = [];
          }
          console.log(nowProduct);
          for (let ele of [
            ClientPlatform.Web,
            ClientPlatform.Mobile,
            ClientPlatform.AndroidApp,
            ClientPlatform.iOSApp,
          ]) {
            const { cursor, len, results } = eachPlatformCursor[ele];
            if (
              cursor < len &&
              nowProduct.node.id === results[cursor].node.id
            ) {
              nowProduct.node.platformsOfCollecitonProduct.push(ele);
              eachPlatformCursor[ele].cursor += 1;
            }
          }
        }
        this.collectionProductPlatformsDone = true;
      }
    },
    formProductChanged(evt: DraggableChangedEvent): void {
      if (evt?.added) {
        const addedProdId = evt.added.element.node.id;
        const insertedIdx = evt.added.newIndex;
        const isDupProds = this.checkDupProductIds(
          [addedProdId],
          [insertedIdx]
        );
        if (isDupProds.length) {
          this.$modal.show(
            {
              title: `중복 상품 id : ${isDupProds} 가 존재합니다. 추가 하시겠습니까?`,
              type: 'warning',
              showCancelButton: true,
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            () => {
              // add된 상품 삭제
              this.form.products.edges.splice(insertedIdx, 1);
            }
          );
        }
      }
    },
    updateCollectionImages(param: {
      contentId: string;
      url: string;
      condition: CollectionCondition;
    }): void {
      const findImageIdx = this.form.images.findIndex(
        image =>
          image.platform.includes(param.condition.platform[0]) &&
          param.condition.exposureType === image.exposureType
      );
      if (findImageIdx === -1) {
        this.form.images.push({
          platform: param.condition.platform,
          content: { id: param.contentId, uri: param.url },
          exposureType: param.condition.exposureType,
        });
      } else {
        this.form.images.splice(findImageIdx, 1, {
          platform: param.condition.platform,
          content: { id: param.contentId, uri: param.url },
          exposureType: param.condition.exposureType,
        });
      }
    },
    getContentByCondition(condition: CollectionCondition): {
      contentId: string;
      url: string;
    } {
      let result = { contentId: '', url: '' };
      if (this.form.images && this.form.images.length) {
        const findImage = this.form.images.find(
          image =>
            image.platform.includes(condition.platform[0]) &&
            image.exposureType === condition.exposureType
        );
        if (findImage) {
          result = {
            contentId: findImage.content.id,
            url: findImage.content.uri,
          };
        }
      }
      return result;
    },
    changeHomeShowStartAt(isoString: string): void {
      this.form.homeShowStartAt = new Date(isoString).getTime();
    },
    changeHomeShowEndAt(isoString: string): void {
      this.form.homeShowEndAt = new Date(isoString).getTime();
    },
    changeListShowStartAt(isoString: string): void {
      this.form.listShowStartAt = new Date(isoString).getTime();
    },
    changeListShowEndAt(isoString: string): void {
      this.form.listShowEndAt = new Date(isoString).getTime();
    },
    instantToIsoString(instant: number | null): string {
      if (!instant) {
        return '';
      }
      return new Date(instant).toISOString();
    },
    searchProducts() {
      if (this.productSearchType === 'name') {
        if (this.searchParam.filter) {
          this.searchParam.filter.ids = undefined;
          this.searchParam.filter.searchKeyword = this.searchText;
        }
      }
      if (this.productSearchType === 'id') {
        if (this.searchParam.filter) {
          this.searchParam.filter.searchKeyword = undefined;
          const searchIds = this.searchText.split(',').map(id => id.trim());
          this.searchParam.filter.ids = searchIds;
        }
      }
      this.isFirstView = false;
      this.$apollo.queries.products.refetch();
    },
    getImageContents(): CollectionImageContent[] {
      let result = [];
      const images = this.form.images;
      const conditions = this.slot.conditions;

      const validImages = images.filter(image => image.content.id.length > 0);
      result = validImages.map(image => {
        const condition = conditions.find(
          condition =>
            condition.platform.includes(image.platform[0]) &&
            image.exposureType === condition.exposureType
        );
        return {
          id: image.content.id || '0',
          width: condition?.imageWidth || 0,
          height: condition?.imageHeight || 0,
          platform: condition?.platform || [],
          exposureType:
            condition?.exposureType || CollectionConditionExposureType.MAIN,
        };
      });
      return result;
    },
    getUpdateParam(): ThemeCollectionUpdateParam {
      return {
        id: this.form.id,
        title: this.form.title,
        displayProductSize: this.form.displayProductSize
          ? +this.form.displayProductSize
          : null,
        displayProductImage: this.form.displayProductImage,
        displayMainImage: this.form.displayMainImage,
        listType: this.form.listType,
        description: this.form.description,
        homeShowStartAt:
          this.isHomeBooked === 'autoSetting'
            ? this.form.homeShowStartAt || null
            : null,
        homeShowEndAt:
          this.isHomeBooked === 'autoSetting'
            ? this.form.homeShowEndAt || null
            : null,
        listShowStartAt:
          this.isListBooked === 'autoSetting'
            ? this.form.listShowStartAt || null
            : null,
        listShowEndAt:
          this.isListBooked === 'autoSetting'
            ? this.form.listShowEndAt || null
            : null,
        products: this.form.products.edges.map(edge => {
          return {
            productId: edge.node.id,
            displayable: isDisplayable(edge.node.status),
            isBenepia: edge.node.isBenepia || false,
            platforms: edge.node.platformsOfCollecitonProduct,
          };
        }),
        slotId: this.slotId,
        imageContents: this.getImageContents(),
        isRandomOrder: this.form.isRandomOrder,
      };
    },
    getCreateParam(): ThemeCollectionCreateParam {
      return {
        title: this.form.title,
        description: this.form.description,
        homeShowStartAt:
          this.isHomeBooked === 'autoSetting'
            ? this.form.homeShowStartAt || null
            : null,
        homeShowEndAt:
          this.isHomeBooked === 'autoSetting'
            ? this.form.homeShowEndAt || null
            : null,
        listShowStartAt:
          this.isListBooked === 'autoSetting'
            ? this.form.listShowStartAt || null
            : null,
        listShowEndAt:
          this.isListBooked === 'autoSetting'
            ? this.form.listShowEndAt || null
            : null,
        displayProductImage: this.form.displayProductImage || null,
        displayMainImage: this.form.displayMainImage || null,
        displayProductSize: this.form.displayProductSize
          ? +this.form.displayProductSize
          : null,
        listType: this.form.listType || null,
        products: this.form.products.edges.map(edge => {
          return {
            productId: edge.node.id,
            displayable: isDisplayable(edge.node.status),
            isBenepia: edge.node.isBenepia || false,
            platforms: edge.node.platformsOfCollecitonProduct,
          };
        }),
        slotId: this.slotId,
        imageContents: this.getImageContents(),
        isRandomOrder: this.form.isRandomOrder,
      };
    },
    async saveCollectionTheme(): Promise<void> {
      this.$modal.show(
        {
          title: '정말 저장 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            if (!this.isCreatePage()) {
              const param = this.getUpdateParam();
              await collectionService.updateThemeCollection(param);
              this.initData();
              await this.fetchCollections();
            }
            if (this.isCreatePage()) {
              const param = this.getCreateParam();
              const collection = await collectionService.createThemeCollection(
                param
              );
              this.initData();
              await this.setupForCachedUi({ collectionId: collection.id });
              this.$router.push({
                name: 'CollectionThemeUpdate',
                params: {
                  slotId: this.slotId,
                  collectionId: collection.id,
                },
              });
            }
            this.showSuccessMessage('저장 완료 되었습니다.');
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    async setupForCachedUi(param: { collectionId: string }): Promise<void> {
      this.collectionId = param.collectionId;
      await this.fetchCollections();
    },
    async deleteCollection(): Promise<void> {
      this.$modal.show(
        {
          title: '정말 삭제하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            await collectionService.deleteCollection(this.form.id);
            this.showSuccessMessage('삭제 되었습니다.');
            this.$router.push({
              name: 'CollectionThemeList',
              params: { slotId: this.slotId },
            });
          } catch (err) {
            console.error(err);
            this.showErrorMessage(err.message);
          }
        }
      );
    },
    deleteProduct(index: number): void {
      this.form.products.edges.splice(index, 1);
    },
    addProduct(index: number): void {
      const slicedProduct = this.products.slice(index, index + 1);
      if (slicedProduct.length === 1) {
        const dupProducts = this.checkDupProductIds([slicedProduct[0].node.id]);
        if (dupProducts.length) {
          this.$modal.show(
            {
              title: `중복 상품 id : ${dupProducts} 가 존재합니다. 추가 하시겠습니까?`,
              type: 'warning',
              showCancelButton: true,
            },
            () => {
              const product = this.products.splice(index, 1);
              this.form.products.edges.unshift({
                node: product[0].node,
                cursor: '',
              });
            }
          );
        } else {
          const product = this.products.splice(index, 1);
          this.form.products.edges.unshift({
            node: product[0].node,
            cursor: '',
          });
        }
      }
    },
    showErrorMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '실패',
        message: message.replace('GraphQL error:', ''),
        type: 'warning',
      });
    },
    showSuccessMessage(message: string): void {
      this.$notify({
        duration: 5,
        title: '성공',
        message: message,
        type: 'success',
      });
    },
    isCreatePage(): boolean {
      return !this.collectionId || this.collectionId.length === 0;
    },
    convertDefaultTableListingProductEdge(
      param: ListingProductV4Edge
    ): TableListingProductEdge {
      const { node, cursor } = param;
      const { ...rest } = node;
      return {
        node: {
          isChecked: false,
          platformsOfCollecitonProduct: [
            ClientPlatform.Web,
            ClientPlatform.Mobile,
            ClientPlatform.AndroidApp,
            ClientPlatform.iOSApp,
          ],
          ...rest,
        },
        cursor: cursor,
      };
    },
    changeAllSearchedProductCheckBox(param: boolean): void {
      this.products.forEach(product => {
        product.node.isChecked = param;
      });
    },
    addCheckedSearchedProduct(): void {
      const checkedProducts = this.products.filter(
        product => product.node.isChecked
      );
      if (checkedProducts.length) {
        const dupProducts = this.checkDupProductIds(
          checkedProducts.map(edge => edge.node.id)
        );
        if (dupProducts.length) {
          this.$modal.show(
            {
              title: `중복 상품 존재합니다. 추가 하시겠습니까?`,
              type: 'warning',
              message: `중복 상품 id : ${dupProducts}`,
              showCancelButton: true,
            },
            () => {
              const products = checkedProducts.map(
                this.convertSearchedProdutEdgeToCollectionProductEdge
              );
              this.form.products.edges.unshift(...products);
              this.products = this.products.filter(
                product => !product.node.isChecked
              );
            }
          );
        } else {
          const products = checkedProducts.map(
            this.convertSearchedProdutEdgeToCollectionProductEdge
          );
          this.form.products.edges.unshift(...products);
          this.products = this.products.filter(
            product => !product.node.isChecked
          );
        }
      }
    },
    convertSearchedProdutEdgeToCollectionProductEdge(
      param: TableListingProductEdge
    ): CollectionProductEdge {
      const { node, cursor } = param;
      return { node, cursor };
    },
    checkDupProductIds(ids: string[], exceptIdxs?: number[]): string[] {
      let result: string[] = [];
      const existIds = this.form.products.edges.map(edge => edge.node.id);
      if (existIds.length) {
        existIds.forEach((existId, idx) => {
          if (ids.includes(existId)) {
            if (!exceptIdxs?.length) {
              result.push(existId);
            } else if (exceptIdxs?.length && !exceptIdxs?.includes(idx)) {
              result.push(existId);
            }
          }
        });
      }
      return [...new Set(result)];
    },
  },
  apollo: {
    form: {
      query: COLLECTION_QUERY,
      variables(): {
        id: string;
        page?: number;
        size?: number;
        filter: CollectionProductFilterInput;
      } {
        return {
          id: this.collectionId,
          page: 1,
          size: 300,
          filter: {
            statusIn: [CommonState.ACTIVE, CommonState.INACTIVE],
            platformsIn: [
              ClientPlatform.Web,
              ClientPlatform.Mobile,
              ClientPlatform.AndroidApp,
              ClientPlatform.iOSApp,
            ],
          },
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { collection: Collection };
      }): Collection => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.collection));
      },
      result(
        result: ApolloQueryResult<{ collection: { collection: Collection } }>
      ): void {
        if (result.data) {
          const collection = result.data.collection.collection;
          this.isHomeBooked =
            collection.homeShowStartAt || collection.homeShowEndAt
              ? 'autoSetting'
              : 'manualSetting';
          this.isListBooked =
            collection.listShowStartAt || collection.listShowEndAt
              ? 'autoSetting'
              : 'manualSetting';
        }
      },
      skip(): boolean {
        return this.isCreatePage();
      },
    },
    collectionClientQuery: {
      query: COLLECTION_QUERY,
      variables(): {
        id: string;
        page?: number;
        size?: number;
        filter?: CollectionProductFilterInput;
      } {
        return {
          id: this.collectionId,
          page: 1,
          size: 300,
          filter: {
            statusIn: [CommonState.ACTIVE],
            platformsIn: [
              ClientPlatform.Web,
              ClientPlatform.Mobile,
              ClientPlatform.AndroidApp,
              ClientPlatform.iOSApp,
            ],
          },
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { collection: Collection };
      }): Collection => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.collection));
      },
      skip(): boolean {
        return this.isCreatePage();
      },
    },
    collectionClientQueryWEB: {
      query: COLLECTION_QUERY,
      variables(): {
        id: string;
        page?: number;
        size?: number;
        filter: CollectionProductFilterInput;
      } {
        return {
          id: this.collectionId,
          page: 1,
          size: 300,
          filter: {
            statusIn: [CommonState.ACTIVE, CommonState.INACTIVE],
            platformsIn: [ClientPlatform.Web],
          },
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { collection: Collection };
      }): Collection => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.collection));
      },
      skip(): boolean {
        return this.isCreatePage();
      },
    },

    collectionClientQueryMOBILE: {
      query: COLLECTION_QUERY,
      variables(): {
        id: string;
        page?: number;
        size?: number;
        filter: CollectionProductFilterInput;
      } {
        return {
          id: this.collectionId,
          page: 1,
          size: 300,
          filter: {
            statusIn: [CommonState.ACTIVE, CommonState.INACTIVE],
            platformsIn: [ClientPlatform.Mobile],
          },
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { collection: Collection };
      }): Collection => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.collection));
      },
      skip(): boolean {
        return this.isCreatePage();
      },
    },

    collectionClientQueryAOS: {
      query: COLLECTION_QUERY,
      variables(): {
        id: string;
        page?: number;
        size?: number;
        filter: CollectionProductFilterInput;
      } {
        return {
          id: this.collectionId,
          page: 1,
          size: 300,
          filter: {
            statusIn: [CommonState.ACTIVE, CommonState.INACTIVE],
            platformsIn: [ClientPlatform.AndroidApp],
          },
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { collection: Collection };
      }): Collection => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.collection));
      },
      skip(): boolean {
        return this.isCreatePage();
      },
    },

    collectionClientQueryiOS: {
      query: COLLECTION_QUERY,
      variables(): {
        id: string;
        page?: number;
        size?: number;
        filter: CollectionProductFilterInput;
      } {
        return {
          id: this.collectionId,
          page: 1,
          size: 300,
          filter: {
            statusIn: [CommonState.ACTIVE, CommonState.INACTIVE],
            platformsIn: [ClientPlatform.iOSApp],
          },
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { collection: Collection };
      }): Collection => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.collection));
      },
      skip(): boolean {
        return this.isCreatePage();
      },
    },
    products: {
      query: LISTINGPRODUCTS_QUERY,
      deep: true, // variables object change
      variables(): ListingProductsParam {
        return this.searchParam;
      },
      error(error: ApolloError) {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update(data: {
        product: {
          listingProductsV4: ListingProductV4Connection;
        };
      }): TableListingProductEdge[] {
        const result = data.product.listingProductsV4.edges.map(
          this.convertDefaultTableListingProductEdge
        );
        return JSON.parse(JSON.stringify(result));
      },
      skip(): boolean {
        return this.isFirstView;
      },
      loadingKey: 'productsLoading',
    },
    slot: {
      query: SLOT_QUERY,
      variables(): { id: string } {
        return {
          id: this.slotId,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorMessage(error.message);
      },
      update: (data: {
        collection: { slot: CollectionSlot };
      }): Omit<CollectionSlot, 'collections' | 'countOfVisibleCollections'> => {
        // query result must be deep copy = implicit caching
        return JSON.parse(JSON.stringify(data.collection.slot));
      },
    },
  },
});
